import {
  USER_BUSINESS_ACCESS,
  USER_INFO,
  USER_TOKEN_BASE,
  USER_TOKEN_SECURE,
} from "./Constants";

export function getUser() {
  return JSON.parse(localStorage.getItem(USER_INFO));
}

export function setUser(user) {
  localStorage.setItem(USER_INFO, JSON.stringify(user));
}

export function clearUser() {
  localStorage.removeItem(USER_INFO);
}

export function getBaseToken() {
  return localStorage.getItem(USER_TOKEN_BASE);
}

export function getSecureToken() {
  return localStorage.getItem(USER_TOKEN_SECURE);
}

export function setTokens(baseToken, secureToken) {
  localStorage.setItem(USER_TOKEN_BASE, baseToken);
  localStorage.setItem(USER_TOKEN_SECURE, secureToken);
}

export function clearTokens() {
  localStorage.removeItem(USER_TOKEN_BASE);
  localStorage.removeItem(USER_TOKEN_SECURE);
}

export function setBusinessesUserHasAccessTo(businessIds) {
  localStorage.setItem(USER_BUSINESS_ACCESS, JSON.stringify(businessIds));
}

export function getBusinessesUserHasAccessTo() {
  return JSON.parse(localStorage.getItem(USER_BUSINESS_ACCESS));
}

export function clearBusinessesUserHasAccessTo() {
  localStorage.removeItem(USER_BUSINESS_ACCESS);
}

export function getUserTokenHeaders() {
  return {
    headers: {
      ebtc: getBaseToken(),
      estc: getSecureToken(),
    },
  };
}
