import axios from "axios";
import { API_HOST } from "./API";
import { getBaseToken, getSecureToken } from "./User";
//const API_HOST = "http://localhost:8080"

export const getBusinessById = async (id, callback) => {
  try {
    const result = (
      await axios.get(
        `${API_HOST}/business/v1/merchant-portal/business/${id}`,
        {
          headers: {
            ebtc: getBaseToken(),
            estc: getSecureToken(),
          },
        }
      )
    ).data;
    callback(result);
    return result;
  } catch (e) {
    console.error(e);
  }
};

export const getBatchOfBusinesses = async (ids, callback) => {
  try {
    const result = (
      await axios.post(
        `${API_HOST}/business/v1/fetch-set?customerId=-1`,
        {
          ids: ids,
        },
        {
          headers: {
            ebtc: getBaseToken(),
            estc: getSecureToken(),
          },
        }
      )
    ).data;
    callback(result);
    return result;
  } catch (e) {
    console.error(e);
  }
};

export const patchBusiness = async (id, update, callback) => {
  try {
    const result = (
      await axios.patch(
        `${API_HOST}/business/v1/merchant-portal/business/${id}`,
        update,
        {
          headers: {
            ebtc: getBaseToken(),
            estc: getSecureToken(),
          },
        }
      )
    ).data;
    callback(result);
    return result;
  } catch (e) {
    console.error(e);
  }
};

export const patchBusinessTags = async (id, category, tags, callback) => {
  try {
    const result = (
      await axios.patch(
        `${API_HOST}/business/v1/merchant-portal/business/${id}/tags/${category}`,
        tags,
        {
          headers: {
            ebtc: getBaseToken(),
            estc: getSecureToken(),
          },
        }
      )
    ).data;
    callback(result);
    return result;
  } catch (e) {
    console.error(e);
  }
};

export const getBusinessHoursById = async (id, callback) => {
  try {
    const result = (
      await axios.get(
        `${API_HOST}/business/v1/merchant-portal/business/${id}/hours`,
        {
          headers: {
            ebtc: getBaseToken(),
            estc: getSecureToken(),
          },
        }
      )
    ).data;
    callback(result);
    return result;
  } catch (e) {
    console.error(e);
  }
};

export const upsertBusinessHours = async (businessId, hours, callback) => {
  try {
    const result = (
      await axios.post(`${API_HOST}/business/v1/hours/${businessId}`, hours, {
        headers: {
          ebtc: getBaseToken(),
          estc: getSecureToken(),
        },
      })
    ).data;
    callback(result);
    return result;
  } catch (e) {
    console.error(e);
  }
};
export const syncBusinessHoursById = async (id, callback) => {
  try {
    const result = (
      await axios.post(
        `${API_HOST}/business/v1/merchant-portal/business/${id}/hours/sync`,
        null,
        {
          headers: {
            ebtc: getBaseToken(),
            estc: getSecureToken(),
          },
        }
      )
    ).data;
    callback(result);
    return result;
  } catch (e) {
    console.error(e);
  }
};

export const getBusinesses = async (ids, callback) => {
  try {
    const result = (
      await axios.post(
        `${API_HOST}/business/v1/fetch-set`,
        {
          ids: ids,
        },
        {
          headers: {
            ebtc: "x-service-to-service-customer-id",
            estc: "1",
          },
        }
      )
    ).data;
    callback(result);
    return result;
  } catch (e) {
    console.error(e);
  }
};

export const getAllBusinesses = async (callback) => {
  try {
    const result = (
      await axios.get(`${API_HOST}/business/v1/`, {
        headers: {
          ebtc: "x-service-to-service-customer-id",
          estc: "1",
        },
      })
    ).data;
    callback(result);
    return result;
  } catch (e) {
    console.error(e);
  }
};

export const deleteBusiness = async (id, callback) => {
  try {
    const result = (
      await axios.delete(
        `${API_HOST}/business/v1/merchant-portal/business/${id}`,
        {
          headers: {
            ebtc: getBaseToken(),
            estc: getSecureToken(),
          },
        }
      )
    ).data;
    callback(result);
    return result;
  } catch (e) {
    console.error(e);
  }
};

export const testHeroImageUrl = async (url, callback) => {
  try {
    const result = await axios.head(url);
    callback(result);
    return result;
  } catch (e) {
    console.error(e);
  }
};

export const uploadHeroImageByUrl = async (businessId, url, callback) => {
  try {
    const result = (
      await axios.post(
        `${API_HOST}/business/v1/${businessId}/upload-hero-by-url`,
        {
          businessId: businessId,
          url: url,
        },
        {
          headers: {
            ebtc: getBaseToken(),
            estc: getSecureToken(),
          },
        }
      )
    ).data;
    callback(result);
    return result;
  } catch (e) {
    console.error(e);
  }
};

export const uploadHeroImage = async (businessId, file, callback) => {
  let formData = new FormData();
  formData.append("file", file);

  try {
    callback(
      (
        await axios.post(
          `${API_HOST}/business/v1/${businessId}/upload-hero`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              ebtc: getBaseToken(),
              estc: getSecureToken(),
            },
          }
        )
      ).data
    );
  } catch (e) {
    console.error(e);
  }
};

export const uploadImage = async (businessId, file, path, callback) => {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("path", path);

  try {
    callback(
      (
        await axios.post(
          `${API_HOST}/business/v1/${businessId}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              ebtc: getBaseToken(),
              estc: getSecureToken(),
            },
          }
        )
      ).data
    );
  } catch (e) {
    console.error(e);
  }
};

export const uploadImageByUrl = async (businessId, url, path, callback) => {
  try {
    const result = (
      await axios.post(
        `${API_HOST}/business/v1/${businessId}/upload-by-url`,
        {
          businessId: businessId,
          url: url,
          path: path,
        },
        {
          headers: {
            ebtc: getBaseToken(),
            estc: getSecureToken(),
          },
        }
      )
    ).data;
    callback(result);
    return result;
  } catch (e) {
    console.error(e);
  }
};
