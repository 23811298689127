import React from "react";
import Button from "@mui/material/Button";
import { useHistory } from "react-router";
import Popover from "@mui/material/Popover";

export const InternalToolsNavigator = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const history = useHistory();

  const pages = [
    {
      label: "🔎 Business Search",
      page: "/internal/business",
    },
    {
      label: "🔢 Data Viewer",
      page: "/internal/data",
    },
    {
      label: "🧑‍💻 Users",
      page: "/internal/users",
    },
    {
      label: "📱 App Users",
      page: "/internal/customers",
    },
    {
      label: "🗺️ User/Business Heatmap",
      page: "/internal/customers/heatmap",
    },
    {
      label: "📈 Usage Analytics",
      page: "/internal/usage-analytics",
    },
    {
      label: "🔔 Notifications",
      page: "/internal/notification-station",
    },
    {
      label: "📽️ Session Replays",
      page: "/internal/session-replays",
    },
    {
      label: "📸 Auto-Fynder",
      page: "/auto-fynder",
    },
  ];

  return (
    <div>
      <Button
        style={{ color: "white" }}
        aria-describedby={id}
        variant="text"
        onClick={handleClick}
      >
        Internal Tools
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div
          style={{ borderRadius: "10%", textAlign: "left", marginTop: "5%" }}
        >
          <ul
            style={{
              listStyleType: "none",
              padding: 0,
              margin: 0,
            }}
          >
            {pages.map((page) => (
              <li>
                <Button
                  style={{
                    color: "white",
                    backgroundColor: "#6A54F3",
                    marginLeft: "5%",
                    marginTop: "2.5%",
                    marginBottom: "2.5%",
                  }}
                  aria-describedby={id}
                  variant="text"
                  key={page.label}
                  onClick={() => {
                    history.push(page.page);
                  }}
                >
                  {page.label}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </Popover>
    </div>
  );
};
