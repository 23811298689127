import axios from "axios";
import { API_HOST } from "./API";
import { getBaseToken, getSecureToken } from "./User";

//const API_HOST = "http://localhost:8080"

export const attemptLogin = async (email, password, onSuccess, onFailure) => {
  try {
    await axios
      .post(
        `${API_HOST}/business/v1/merchant-portal/auth/login`,
        {},
        {
          headers: {
            email: email,
            password: password,
          },
        }
      )
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error(e);
    onFailure(e);
  }
};

export const registerUser = async (
  firstName,
  lastName,
  email,
  password,
  onSuccess,
  onFailure
) => {
  try {
    await axios
      .post(
        `${API_HOST}/business/v1/merchant-portal/users`,
        {
          email: email,
          firstName: firstName,
          lastName: lastName,
        },
        {
          headers: {
            password: password,
          },
        }
      )
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error("Error", e);
    onFailure(e);
  }
};

export const signInGoogleUser = async (
  googleConnectInfo,
  onSuccess,
  onFailure
) => {
  try {
    await axios
      .post(
        `${API_HOST}/business/v1/merchant-portal/connected-accounts/google`,
        googleConnectInfo
      )
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error("Error", e);
    onFailure(e);
  }
};

export const registerUserWithInvite = async (
  firstName,
  lastName,
  email,
  password,
  invite,
  onSuccess,
  onFailure
) => {
  try {
    await axios
      .post(
        `${API_HOST}/business/v1/merchant-portal/users/accept-invite-and-create-user`,
        {
          user: {
            email: email,
            firstName: firstName,
            lastName: lastName,
          },
          invite: invite,
        },
        {
          headers: {
            password: password,
          },
        }
      )
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error("Error", e);
    onFailure(e);
  }
};

export const acceptInvite = async (invite, onSuccess, onFailure) => {
  try {
    await axios
      .post(
        `${API_HOST}/business/v1/merchant-portal/users/accept-invite`,
        invite,
        {
          headers: {
            ebtc: getBaseToken(),
            estc: getSecureToken(),
          },
        }
      )
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error("Error", e);
    onFailure(e);
  }
};

export const sendInvite = async (
  emailToInvite,
  accountId,
  onSuccess,
  onFailure
) => {
  try {
    await axios
      .post(
        `${API_HOST}/business/v1/merchant-portal/users/invite-user`,
        {
          expectedEmail: emailToInvite,
          accountId: accountId,
        },
        {
          headers: {
            ebtc: getBaseToken(),
            estc: getSecureToken(),
          },
        }
      )
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error("Error", e);
    onFailure(e);
  }
};

export const logout = async (onSuccess, onFailure) => {
  try {
    await axios
      .post(
        `${API_HOST}/business/v1/merchant-portal/auth/logout`,
        {},
        {
          headers: {
            ebtc: getBaseToken(),
            estc: getSecureToken(),
          },
        }
      )
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error(e);
    onFailure(e);
  }
};

export const doesHaveAccessToInternalTools = async (
  onHasAccess,
  onDoesNotHaveAccess
) => {
  try {
    await axios
      .get(`${API_HOST}/business/v1/merchant-portal/users/internal-access/`, {
        headers: {
          ebtc: getBaseToken(),
          estc: getSecureToken(),
        },
      })
      .then((r) => {
        if (r.data.hasAccess) {
          onHasAccess();
        } else {
          onDoesNotHaveAccess();
        }
      })
      .catch(onDoesNotHaveAccess);
  } catch (e) {
    console.error(e);
    onDoesNotHaveAccess();
  }
};

export const doesHaveAccessToBusiness = async (
  businessId,
  onHasAccess,
  onDoesNotHaveAccess
) => {
  try {
    await axios
      .get(
        `${API_HOST}/business/v1/merchant-portal/users/business-access/${businessId}`,
        {
          headers: {
            ebtc: getBaseToken(),
            estc: getSecureToken(),
          },
        }
      )
      .then((r) => {
        if (r.data.hasAccess) {
          onHasAccess();
        } else {
          onDoesNotHaveAccess();
        }
      })
      .catch(onDoesNotHaveAccess);
  } catch (e) {
    console.error(e);
    onDoesNotHaveAccess();
  }
};

export const getListOfBusinessesUserHasAccessTo = async (
  onSuccess,
  onFailure
) => {
  try {
    await axios
      .get(`${API_HOST}/business/v1/merchant-portal/users/business-access`, {
        headers: {
          ebtc: getBaseToken(),
          estc: getSecureToken(),
        },
      })
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error(e);
    onFailure(e);
  }
};

export const getListOfBusinessesUserHasAccessToForUser = async (
  userId,
  onSuccess,
  onFailure
) => {
  try {
    await axios
      .get(
        `${API_HOST}/business/v1/merchant-portal/users/business-access?userId=${userId}`,
        {
          headers: {
            ebtc: getBaseToken(),
            estc: getSecureToken(),
          },
        }
      )
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error(e);
    onFailure(e);
  }
};

export const getListOfAllBusinesses = async (onSuccess, onFailure) => {
  try {
    await axios
      .get(`${API_HOST}/business/v1/`, {
        headers: {
          ebtc: getBaseToken(),
          estc: getSecureToken(),
        },
      })
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error(e);
    onFailure(e);
  }
};

export const getAllUsers = async (onSuccess, onFailure) => {
  try {
    await axios
      .get(`${API_HOST}/business/v1/merchant-portal/users`, {
        headers: {
          ebtc: getBaseToken(),
          estc: getSecureToken(),
        },
      })
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error(e);
    onFailure(e);
  }
};

export const getUser = async (id, onSuccess, onFailure) => {
  try {
    await axios
      .get(`${API_HOST}/business/v1/merchant-portal/users/${id}`, {
        headers: {
          ebtc: getBaseToken(),
          estc: getSecureToken(),
        },
      })
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error(e);
    onFailure(e);
  }
};

export const updateUser = async (id, user, onSuccess, onFailure) => {
  try {
    await axios
      .patch(`${API_HOST}/business/v1/merchant-portal/users/${id}`, user, {
        headers: {
          ebtc: getBaseToken(),
          estc: getSecureToken(),
        },
      })
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error(e);
    onFailure(e);
  }
};

export const deleteUser = async (id, onSuccess, onFailure) => {
  try {
    await axios
      .delete(`${API_HOST}/business/v1/merchant-portal/users/${id}`, {
        headers: {
          ebtc: getBaseToken(),
          estc: getSecureToken(),
        },
      })
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error(e);
    onFailure(e);
  }
};

export const triggerPasswordResetForUser = async (
  email,
  onSuccess,
  onFailure
) => {
  try {
    await axios
      .post(
        `${API_HOST}/business/v1/merchant-portal/users/initiate-reset-password?email=${email}`
      )
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error("Error", e);
    onFailure(e);
  }
};

export const resetPassword = async (
  email,
  code,
  password,
  onSuccess,
  onFailure
) => {
  try {
    await axios
      .post(
        `${API_HOST}/business/v1/merchant-portal/users/reset-password?email=${email}&code=${code}`,
        null,
        {
          headers: {
            password: password,
          },
        }
      )
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error("Error", e);
    onFailure(e);
  }
};

export const invalidatePasswordReset = async (
  email,
  code,
  onSuccess,
  onFailure
) => {
  try {
    await axios
      .delete(
        `${API_HOST}/business/v1/merchant-portal/users/invalidate-reset-password?email=${email}&code=${code}`
      )
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error("Error", e);
    onFailure(e);
  }
};

export const impersonateMerchantPortalUser = async (
  userId,
  onSuccess,
  onFailure
) => {
  try {
    await axios
      .post(
        `${API_HOST}/business/v1/merchant-portal/auth/impersonate?userId=${userId}`,
        null,
        {
          headers: {
            ebtc: getBaseToken(),
            estc: getSecureToken(),
          },
        }
      )
      .then((d) => d.data)
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error("Error", e);
    onFailure(e);
  }
};

export const impersonateAppUser = async (userId, onSuccess, onFailure) => {
  try {
    await axios
      .post(`${API_HOST}/business/v1/auth/impersonate?userId=${userId}`, null, {
        headers: {
          ebtc: getBaseToken(),
          estc: getSecureToken(),
        },
      })
      .then((d) => d.data)
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error("Error", e);
    onFailure(e);
  }
};

export const invalidateImpersonatingMerchantPortalUser = async (
  baseToken,
  secureToken,
  onSuccess,
  onFailure
) => {
  try {
    await axios
      .post(
        `${API_HOST}/business/v1/merchant-portal/auth/invalidate`,
        {
          baseToken: baseToken,
          secureToken: secureToken,
        },
        {
          headers: {
            ebtc: getBaseToken(),
            estc: getSecureToken(),
          },
        }
      )
      .then((d) => d.data)
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error("Error", e);
    onFailure(e);
  }
};

export const invalidateImpersonatingAppUser = async (
  baseToken,
  secureToken,
  onSuccess,
  onFailure
) => {
  try {
    await axios
      .post(
        `${API_HOST}/business/v1/auth/invalidate`,
        {
          baseToken: baseToken,
          secureToken: secureToken,
        },
        {
          headers: {
            ebtc: getBaseToken(),
            estc: getSecureToken(),
          },
        }
      )
      .then((d) => d.data)
      .then(onSuccess)
      .catch(onFailure);
  } catch (e) {
    console.error("Error", e);
    onFailure(e);
  }
};
