import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  clearBusinessesUserHasAccessTo,
  clearTokens,
  clearUser,
  getUser,
} from "../api/User";
import Button from "@mui/material/Button";
import {
  doesHaveAccessToBusiness,
  doesHaveAccessToInternalTools,
  logout,
  sendInvite,
} from "../api/UsersService";
import { useHistory, useParams } from "react-router";
import Popover from "@mui/material/Popover";
import { Link, TextField } from "@mui/material";
import { isLocal } from "../api/API";
import { BusinessSelector } from "./BusinessSelector";
import { InternalToolsNavigator } from "./InternalToolsNavigator";
import { EngineeringToolsNavigator } from "./EngineeringToolsNavigator";

const redirectToLogin = () => (window.location.href = "/login");

export default function Navbar(props) {
  const { enforceInternalOnly, showBusinessSwitcher } = props;

  const [email, setEmail] = useState("");
  const [inviteLink, setInviteLink] = useState("");

  const [hasAccessToInternalTools, setHasAccessToInternalTools] =
    useState(false);

  let history = useHistory();

  let { businessId } = useParams();

  // Validate that the user has access to the requested business, if there is one
  useEffect(() => {
    if (businessId) {
      doesHaveAccessToBusiness(
        businessId,
        () => {
          // User does have acess to this business
          // console.log("User does have access to this business.");
        },
        () => {
          // User does not have access to this business
          // console.log("User does NOT have access to this business.");
          // Either move them back to the login page, a generic "You don't have access page", or redirec to an account they do have access to
          redirectToLogin();
        }
      );
    } else {
      console.log("No business id provided in the path, not validating.");
    }
  }, []);

  // Validate that the user has access to the internal tools & are an employee
  useEffect(() => {
    if (isLocal) {
      // console.log(
      //   "Skipping enforceInternalOnly check since we're running local apis."
      // );
      return;
    }
    doesHaveAccessToInternalTools(
      () => {
        // console.log("User does have access to internal tools.");
        setHasAccessToInternalTools(true);
      },
      () => {
        // console.log("User does NOT have access to use internal tools.");
        setHasAccessToInternalTools(false);
        if (enforceInternalOnly || false) {
          // Either move them back to the login page, a generic "You don't have access page", or redirect to an account they do have access to
          redirectToLogin();
        }
      }
    );
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [supportEl, setSupportEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setInviteLink("");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div
      id="header"
      style={{
        ...props.style,
        backgroundColor: "#6A54F3",
        paddingTop: "1.25%",
        paddingBotton: "1.25%",
        marginBottom: "2%",
        textAlign: "right",
        alignContent: "end",
        alignItems: "end",
        color: "white",
        width: "100%",
        // paddingLeft: "60%"
        // marginLeft: "auto",
        // marginRight: "0px",
        float: "right",
      }}
    >
      <div style={{ display: "flex", float: "right" }}>
        {hasAccessToInternalTools && <EngineeringToolsNavigator />}
        {hasAccessToInternalTools && <InternalToolsNavigator />}

        {(showBusinessSwitcher || true) && <BusinessSelector />}

        <Link href="https://billing.stripe.com/p/login/fZeeVCgAZfJnaic7ss">
          <Button
            style={{ color: "white" }}
            aria-describedby="manage subscription button"
            variant="text"
            // onClick={(event) => setSupportEl(event.currentTarget)}
          >
            Manage Subscription
          </Button>
        </Link>
        <Button
          style={{ color: "white" }}
          aria-describedby="support-popover"
          variant="text"
          onClick={(event) => setSupportEl(event.currentTarget)}
        >
          Support
        </Button>
        <Popover
          id="support-popover"
          open={Boolean(supportEl)}
          anchorEl={supportEl}
          onClose={() => setSupportEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div
            style={{
              padding: "5%",
              fontSize: "14px",
              borderRadius: "10%",
              textAlign: "center",
              marginTop: "5%",
            }}
          >
            <p>Have a question, or just want some help?</p>
            <p>Send us an email at support@getfynd.app</p>
          </div>
        </Popover>

        {(Object.keys(props).includes("showCreateNewBusiness")
          ? props["showCreateNewBusiness"]
          : true) && (
          <Button
            style={{ color: "white", textAlign: "right" }}
            onClick={() => history.push("/onboard")}
          >
            Create Another Business
          </Button>
        )}

        {/* <h1>{user.firstName}</h1> */}
        {(
          Object.keys(props).includes("showInviteUser")
            ? props.showInviteUser
            : true
        ) ? (
          <div style={{ textAlign: "right" }}>
            <Button
              style={{ color: "white" }}
              aria-describedby={id}
              variant="text"
              onClick={handleClick}
            >
              Invite user
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div
                style={{
                  borderRadius: "10%",
                  textAlign: "center",
                  marginTop: "5%",
                }}
              >
                {inviteLink !== "" ? (
                  <div>
                    <Typography sx={{ p: 2 }}>Invite Link:</Typography>
                    <Typography sx={{ p: 2 }}>{inviteLink}</Typography>
                  </div>
                ) : (
                  <div>
                    <TextField
                      style={{}}
                      id="email"
                      variant="outlined"
                      type="email"
                      label="Email to invite"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <Button
                      style={{
                        color: "white",
                        backgroundColor: "#6A54F3",
                        marginLeft: "5%",
                        marginTop: "2.5%",
                        marginBottom: "2.5%",
                      }}
                      aria-describedby={id}
                      variant="text"
                      onClick={() => {
                        sendInvite(
                          email,
                          businessId,
                          (success) => {
                            console.log("Response", success.data.magicLink);
                            setInviteLink(success.data.magicLink);
                          },
                          (fail) => {}
                        );
                      }}
                    >
                      Send Invite
                    </Button>
                  </div>
                )}
              </div>
            </Popover>
          </div>
        ) : (
          <div />
        )}

        <Button
          style={{ color: "white", textAlign: "right" }}
          onClick={() => {
            logout(
              (success) => {
                clearTokens();
                clearUser();
                clearBusinessesUserHasAccessTo();
                redirectToLogin();
              },
              (failure) => {
                // How do we handle issues of failing to sign out
                clearTokens();
                clearUser();
                clearBusinessesUserHasAccessTo();
                redirectToLogin();
              }
            );
          }}
        >
          Sign out
        </Button>
      </div>
    </div>
  );
}
