import axios from "axios";
import { API_HOST } from "./API";
import { getUserTokenHeaders } from "./User";

//const API_HOST = "https://fyndapi.com/local-api-tunnel";
//const API_HOST = "https://api.fyndapi.com";

// const API_HOST = "http://localhost:8080"

export const getCustomerById = async (customerId, callback) => {
  try {
    callback(
      (
        await axios.get(
          `${API_HOST}/business/v1/customers/${customerId}`,
          getUserTokenHeaders()
        )
      ).data
    );
  } catch (e) {
    console.error(e);
  }
};

export const getAllCustomers = async (callback) => {
  try {
    callback(
      (
        await axios.get(
          `${API_HOST}/business/v1/customers/`,
          getUserTokenHeaders()
        )
      ).data
    );
  } catch (e) {
    console.error(e);
  }
};

export const getCustomerZipCodes = async (callback) => {
  try {
    callback(
      (
        await axios.get(
          `${API_HOST}/business/v1/customers/analytics/zipCodes`,
          getUserTokenHeaders()
        )
      ).data
    );
  } catch (e) {
    console.error(e);
  }
};
