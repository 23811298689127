/**
 * This source code is exported from pxCode, you can get more document from https://www.pxcode.io
 */
import { Component } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import { RegionDropdown } from "react-country-region-selector";

export class FormInput extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.setState({ phone: ["", "", ""], value: this.props.value || "" });
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   // do things with nextProps.someProp and prevState.cachedSomeProp

  //   // return {
  //   //   cachedSomeProp: nextProps.someProp,
  //   //   // ... other derived state properties
  //   // };

  //   //console.log("FormInput new props: " + JSON.stringify(nextProps))

  //   return {
  //       value: nextProps.value
  //   };
  // }

  render() {
    switch (this.props.type) {
      case "text":
        return (
          <div className={`form-input ${css(styles.group, styles.group_lyt)}`}>
            <input
              aria-label={this.props.ariaLabel}
              value={this.state.value || this.props.value}
              placeholder={this.props.hint}
              onChange={(v) => {
                this.setState({ value: v.target.value });
                if (this.props.onChange) {
                  this.props.onChange(v.target.value);
                }
              }}
              className={css(styles.text_body, styles.text_body_lyt)}
              {...this.props}
            ></input>
          </div>
        );

      case "textarea":
        return (
          <div className={`form-input ${css(styles.group, styles.group_lyt)}`}>
            <textarea
              value={this.state.value || this.props.value}
              aria-label={this.props.ariaLabel}
              placeholder={this.props.hint}
              onChange={(v) => {
                this.setState({ value: v.target.value });
                if (this.props.onChange) {
                  this.props.onChange(v.target.value);
                }
              }}
              className={css(styles.text_body, styles.text_body_lyt)}
              {...this.props}
            ></textarea>
          </div>
        );

      case "number":
        return (
          <div className={`form-input ${css(styles.group, styles.group_lyt)}`}>
            <input
              type="number"
              aria-label={this.props.ariaLabel}
              value={this.state.value || this.props.value}
              placeholder={this.props.hint}
              onChange={(v) => {
                if (this.props.onChange) {
                  this.props.onChange(v.target.value);
                }
              }}
              className={css(styles.text_body, styles.text_body_lyt)}
              {...this.props}
            ></input>
          </div>
        );

      case "url":
        return (
          <div className={`form-input ${css(styles.group, styles.group_lyt)}`}>
            <input
              type="url"
              aria-label={this.props.ariaLabel}
              value={this.state.value || this.props.value}
              placeholder={this.props.hint}
              onChange={(v) => {
                if (this.props.onChange) {
                  this.props.onChange(v.target.value);
                }
              }}
              className={css(styles.text_body, styles.text_body_lyt)}
              {...this.props}
            ></input>
          </div>
        );

      case "phone":
        return (
          <div
            className={`form-input ${css(styles.group, styles.group_lyt)}`}
            style={{ display: "flex" }}
          >
            <input
              type="number"
              aria-label={this.props.ariaLabel}
              value={this.state.value || this.props.value}
              onChange={(v) => {
                let p = this.phone;
                p[0] = v.target.value;
                this.setState(p);
                if (this.props.onChange) {
                  this.props.onChange(p.join("-"));
                }
              }}
              className={css(styles.text_body, styles.text_body_lyt)}
              {...this.props}
            ></input>
            <p>-</p>
            <input
              type="number"
              aria-label={this.props.ariaLabel}
              onChange={(v) => {
                let p = this.phone;
                p[1] = v.target.value;
                this.setState(p);
                if (this.props.onChange) {
                  this.props.onChange(p.join("-"));
                }
              }}
              className={css(styles.text_body, styles.text_body_lyt)}
              {...this.props}
            ></input>
            <p>-</p>
            <input
              type="number"
              aria-label={this.props.ariaLabel}
              onChange={(v) => {
                let p = this.phone;
                p[2] = v.target.value;
                this.setState(p);
                if (this.props.onChange) {
                  this.props.onChange(p.join("-"));
                }
              }}
              className={css(styles.text_body, styles.text_body_lyt)}
              {...this.props}
            ></input>
          </div>
        );

      case "states":
        return (
          <div className={`form-input ${css(styles.group, styles.group_lyt)}`}>
            {/* <SelectUSState value={this.state.value || this.props.value} className={css(styles.text_body, styles.text_body_lyt)} onChange={(v) => {
            console.log(v);
            if (this.props.onChange) {
              this.props.onChange(v);
            }
          }}/> */}
            <RegionDropdown
              aria-label={this.props.ariaLabel}
              className={css(styles.text_body, styles.text_body_lyt)}
              country={"US"}
              countryValueType="short"
              defaultOptionLabel="State"
              valueType="short"
              value={this.state.value || this.props.value}
              onChange={(val) => {
                this.setState({ value: val });
                if (this.props.onChange) {
                  this.props.onChange(val);
                }
              }}
              {...this.props}
            />
          </div>
        );
    }

    return (
      <div className={`form-input ${css(styles.group, styles.group_lyt)}`}>
        Unknown Type
      </div>
    );
  }
}

export default FormInput;

FormInput.inStorybook = true;

const styles = StyleSheet.create({
  group: { display: "flex", width: "100%" },
  group_lyt: {
    position: "relative",
    overflow: "visible",
    height: "min-content",
    flexGrow: 1,
    margin: 0,
  },
  text_body: {
    backgroundColor: "white",
    justifyContent: "center",
    borderWidth: "0px 0px 1px 0px",
    font: '16px/1.2 "Work Sans", Helvetica, Arial, serif',
    color: "rgb(52,52,52)",
    letterSpacing: "0",
  },
  text_body_lyt: { position: "relative", flexGrow: 1, margin: 0 },
});
