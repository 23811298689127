import React from "react";
import "./IosNotification.css";
import Icon from "../images/appIconDark.png";

export const IosNotification = ({ title, body, image }) => {
  return (
    <div className="ios-notification">
      <div className="title">{title}</div>
      <p className="message">{body}</p>
      {image && <img className="media" alt="Media" src={image} />}
      <div className="time">now</div>
      <img className="app-icon" alt="App icon" src={Icon} />
    </div>
  );
};
