import axios from "axios";
import { useState } from "react";
import { InstantSearch, SearchBox, Hits } from "react-instantsearch-dom";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { API_HOST } from "../../api/API";
import Button from "react-rainbow-components/components/Button";
import Card from "react-rainbow-components/components/Card";
import VerticalSection from "react-rainbow-components/components/VerticalSection";
import VerticalItem from "react-rainbow-components/components/VerticalItem";
import { Toaster } from "react-hot-toast";

const searchClient = instantMeiliSearch(
  `${API_HOST}/search/`,
  "Y0urVery-S3cureAp1K3y"
);

export default function ImageSearch() {
  const [placesId, setPlacesId] = useState("ChIJN1t_tDeuEmsRUsoyG83frY4");

  const [urls, setUrls] = useState([]);

  const search = (id) =>
    axios
      .get(
        `http://localhost:8080/business/v2/merchant-portal/onboarding/image-search?placesId=${
          id || placesId
        }`
      )
      .then((d) => d.data)
      .then(setUrls);

  const Hit = ({ hit }) => {
    return (
      <Card
        //icon={<FontAwesomeIcon icon={faUsers} size="lg" className="rainbow-color_brand" />}
        title={hit.name}
        style={{ borderColor: "white", margin: "1%" }}
        actions={
          <>
            <Button
              label="Search for images"
              variant="brand"
              onClick={() => {
                setPlacesId(hit.googlePlacesId);
                search(hit.googlePlacesId);
              }}
            />
          </>
        }
      >
        <div style={{ display: "flex" }}>
          <img
            style={{ borderRadius: 25 }}
            height={100}
            src={hit.heroImageUrl}
          />
          <div style={{ overflow: "scroll" }}>
            <VerticalItem name="name" label={hit.name} />
            <VerticalItem name="placesId" label={hit.googlePlacesId} />
          </div>
        </div>
      </Card>
    );
  };

  return (
    <div>
      <InstantSearch
        style={{ height: 100 }}
        indexName="businesses"
        searchClient={searchClient}
      >
        <div style={{ fontSize: "5em" }}>
          <SearchBox />
        </div>
        <Toaster />
        <Hits hitComponent={Hit} />
      </InstantSearch>
      <input value={placesId} onChange={(e) => setPlacesId(e.target.value)} />
      <button
        onClick={() => {
          search();
        }}
      >
        Search
      </button>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        {urls.map((url) => (
          <img width={500} src={url} />
        ))}
      </div>
    </div>
  );
}
