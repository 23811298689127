import React from "react";
import Button from "@mui/material/Button";
import { useHistory } from "react-router";
import Popover from "@mui/material/Popover";

export const EngineeringToolsNavigator = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const history = useHistory();

  const pages = [
    {
      label: "Values",
      page: "/internal/values",
    },
    {
      label: "Features",
      page: "/internal/features",
    },
    {
      label: "Loggy (Search Backend Logs)",
      page: "/internal/logs",
    },
    {
      label: "Overwatch (Backend Infra)",
      page: "/internal/overwatch",
    },
    {
      label: "Usage Metrics Explorer",
      page: "/internal/metrics-explorer",
    },
    {
      label: "Global Events Feed",
      page: "/internal/customers/global/feed",
    },
    {
      label: "Internal Status Page",
      page: "/internal/status-page",
    },
    {
      label: "Api Caller",
      page: "/internal/api-caller",
    },
  ];

  return (
    <div>
      <Button
        style={{ color: "white" }}
        aria-describedby={id}
        variant="text"
        onClick={handleClick}
      >
        Engineering Tools
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div
          style={{ borderRadius: "10%", textAlign: "left", marginTop: "5%" }}
        >
          <ul
            style={{
              listStyleType: "none",
              padding: 0,
              margin: 0,
            }}
          >
            {pages.map((page) => (
              <li>
                <Button
                  style={{
                    color: "white",
                    backgroundColor: "#6A54F3",
                    marginLeft: "5%",
                    marginTop: "2.5%",
                    marginBottom: "2.5%",
                  }}
                  aria-describedby={id}
                  variant="text"
                  key={page.label}
                  onClick={() => {
                    history.push(page.page);
                  }}
                >
                  {page.label}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </Popover>
    </div>
  );
};
