import axios from "axios";

var setAccountId;

export function init(accountId) {
  setAccountId = accountId;
}

export async function isEnabled(feature, user) {
  const f = await getFeature(feature);
  if (!f) {
    return false;
  }
  if (f.overrides && f.overrides.includes(parseInt(user))) {
    return true;
  }
  console.log("user score", (f.featureBase * user) % 100);
  return (f.featureBase * user) % 100 < f.percentEnabled;
}

async function getFeature(feature) {
  if (!setAccountId) {
    console.error(
      "No account Id was set for the tenit feature library. Please call Feature.init(accountId); to initalize the library."
    );
    return null;
  }
  //
  return axios
    .get(
      `https://features-gcs.tenitx.com/${setAccountId}/${feature}?t=${Date.now()}`
    )
    .then((r) => r.data);
}
