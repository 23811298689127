import axios from "axios";
import { API_HOST } from "./API";
import { getUserTokenHeaders } from "./User";
// const API_HOST = "http://localhost:8080";

export const sendImpromptuNotification = async (
  notificationSendRequest,
  callback
) => {
  try {
    callback(
      (
        await axios.post(
          `${API_HOST}/notifications/v1/send/impromptu`,
          notificationSendRequest,
          getUserTokenHeaders()
        )
      ).data
    );
  } catch (e) {
    console.error(e);
  }
};

/* Templates */
export const createTemplate = async (template, callback) => {
  try {
    callback(
      (
        await axios.post(
          `${API_HOST}/notifications/v1/templates`,
          template,
          getUserTokenHeaders()
        )
      ).data
    );
  } catch (e) {
    console.error(e);
  }
};

export const updateTemplate = async (template, callback) => {
  try {
    callback(
      (
        await axios.put(
          `${API_HOST}/notifications/v1/templates`,
          template,
          getUserTokenHeaders()
        )
      ).data
    );
  } catch (e) {
    console.error(e);
  }
};

export const getAllTemplates = async (callback) => {
  try {
    callback(
      (
        await axios.get(
          `${API_HOST}/notifications/v1/templates`,
          getUserTokenHeaders()
        )
      ).data
    );
  } catch (e) {
    console.error(e);
  }
};

export const getNotificationCategories = async (callback) => {
  try {
    callback(
      (
        await axios.get(
          `${API_HOST}/notifications/v1/templates/notification-categories`,
          getUserTokenHeaders()
        )
      ).data
    );
  } catch (e) {
    console.error(e);
  }
};

export const deleteNotification = async (templateName, callback) => {
  try {
    callback(
      await axios.delete(
        `${API_HOST}/notifications/v1/templates/${templateName}`,
        getUserTokenHeaders()
      )
    );
  } catch (e) {
    console.error(e);
  }
};
