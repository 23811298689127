import { useEffect, useState } from "react";
import { getBusinesses } from "../api/BusinessService";
import {
  doesHaveAccessToInternalTools,
  getListOfBusinessesUserHasAccessTo,
} from "../api/UsersService";
import { useHistory, useParams, useLocation } from "react-router";
import Option from "react-rainbow-components/components/Option";
import Picklist from "react-rainbow-components/components/Picklist";

export const BusinessSelector = () => {
  const { businessId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [show, setShow] = useState(true);

  const [listOfBusinesses, setListOfBusinesses] = useState([]);

  const [selectedType, setSelectedType] = useState({});

  useEffect(() => {
    doesHaveAccessToInternalTools(
      () => {
        // Load All businesses
        // This causes a really slow list since it is 1000s of items
        //getListOfAllBusinesses(setListOfBusinesses);
      },
      () => {
        // Load whatever businesses they have access to
        getListOfBusinessesUserHasAccessTo((resp) =>
          getBusinesses(resp.data, setListOfBusinesses)
        );
      }
    );
  }, []);

  const selectNewBusiness = (v) => {
    setSelectedType(v);
    history.push(location.pathname.replace(businessId, v.name));
  };

  return (
    show &&
    listOfBusinesses.length > 1 && (
      <div>
        <Picklist
          className="rainbow-p-horizontal_medium rainbow-m_auto"
          style={{ color: "white" }}
          onChange={(value) => selectNewBusiness(value)}
          value={selectedType}
          //label="Switch Account"
          //hideLabel
          enableSearch
        >
          {listOfBusinesses.map((b) => {
            let option = <Option key={b.id} name={b.id} label={b.name} />;
            if (
              b.id === parseInt(businessId) &&
              Object.keys(selectedType).length === 0
            ) {
              setSelectedType({ name: b.id, label: b.name });
            }
            return option;
          })}
        </Picklist>
      </div>
    )
  );
};
