import { useEffect, useState } from "react";
import Card from "react-rainbow-components/components/Card";
import CheckboxToggle from "react-rainbow-components/components/CheckboxToggle";
import Input from "react-rainbow-components/components/Input";
import Navbar from "../../components/Navbar";
import { getAllUsers } from "../../api/UsersService";
import BadgeIcon from "@mui/icons-material/Badge";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { useHistory, useParams } from "react-router";
import { getAllCustomers } from "../../api/CustomersService";
import { CheckBox, Delete, Edit, Place } from "@mui/icons-material";
import {
  createTemplate,
  deleteNotification,
  getAllTemplates,
  getNotificationCategories,
  sendImpromptuNotification,
  updateTemplate,
} from "../../api/NotificationsService";
import { IosNotification } from "../../components/IosNotification";
import FormInput from "../../components/FormInput";

const ALL_MOBILE_NOTIFICATION_USERS_QUERY = `SELECT customerId AS userId FROM connected_accounts WHERE provider = "EXPO_NOTIFICATION"`;

export default function NotificationStation() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(users);

  const [categories, setCategories] = useState([]);
  const [templates, setTemplates] = useState([]);

  const [templateName, setTemplateName] = useState();
  const [title, setTitle] = useState();
  const [body, setBody] = useState();
  const [category, setCategory] = useState();
  const [userQuery, setUserQuery] = useState();
  const [dataMap, setDataMap] = useState();

  const [isEditing, setIsEditing] = useState(false);

  const [selectedTemplate, setSelectedTemplate] = useState();

  const [newTemplateOpen, setNewTemplateOpen] = useState(false);

  useEffect(() => {
    getNotificationCategories(setCategories);
    getAllTemplates(setTemplates);
  }, []);
  return (
    <div style={{ marginTop: 0 }}>
      <Navbar
        style={{ marginTop: "0" }}
        enforceInternalOnly={true}
        showInviteUser={false}
      />
      <Button
        style={{
          fontSize: "20px",
          marginLeft: "40%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
        onClick={() => setNewTemplateOpen(true)}
      >
        Create new template
      </Button>
      <hr style={{ width: "50%" }}></hr>
      {newTemplateOpen && (
        <div style={{ shadow: 10, width: "60%", marginLeft: "20%" }}>
          <Input
            placeholder="Template Name (Just an easy to identify name for the template)"
            type="text"
            value={templateName}
            disabled={isEditing}
            onChange={(v) => setTemplateName(v.target.value)}
          ></Input>
          <Input
            placeholder="Title"
            type="text"
            value={title}
            onChange={(v) => setTitle(v.target.value)}
          ></Input>
          <Input
            placeholder="Body"
            type="text"
            value={body}
            onChange={(v) => setBody(v.target.value)}
          ></Input>
          <InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={category || categories[0]}
            label="Category"
            onChange={(e) => setCategory(e.target.value)}
          >
            {categories.map((c) => (
              <MenuItem value={c}>{c}</MenuItem>
            ))}
          </Select>
          <Input
            hint="Notification Data map"
            type="textarea"
            value={dataMap}
            onChange={(e) => setDataMap(e.target.value)}
          />
          <Input
            placeholder="User Query"
            value={userQuery}
            type="text"
            disabled={userQuery === ALL_MOBILE_NOTIFICATION_USERS_QUERY}
            onChange={(v) => setUserQuery(v.target.value)}
          ></Input>
          <FormGroup>
            <FormControlLabel
              checked={userQuery === ALL_MOBILE_NOTIFICATION_USERS_QUERY}
              control={
                <Switch
                  onChange={(e) =>
                    setUserQuery(
                      e.target.checked
                        ? ALL_MOBILE_NOTIFICATION_USERS_QUERY
                        : ""
                    )
                  }
                ></Switch>
              }
              label={"Send to All users"}
            />
          </FormGroup>
          <Button
            onClick={() =>
              sendImpromptuNotification(
                {
                  userIdQuery: userQuery,
                  category: "SIPS",
                  deliveryMethods: ["MOBILE"],
                  title: "title",
                  body: "body",
                  dataMap: {},
                },
                (data) => setUsers(data)
              )
            }
          >
            Check user query
          </Button>
          <p>Up to {users.length} users would get this notification</p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <IosNotification title={title} body={body} />
          </div>
          <Button
            onClick={() => {
              setUserQuery();
              setTitle();
              setTemplateName();
              setDataMap();
              setCategory();
              setBody();
              setNewTemplateOpen(false);
              setIsEditing(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              const t = {
                userIdQuery: userQuery,
                category: category || categories[0],
                deliveryMethods: ["MOBILE"],
                title: title,
                body: body,
                dataMap: JSON.parse(dataMap),
                templateName: templateName,
              };

              if (isEditing) {
                updateTemplate(t, () => {
                  setUserQuery();
                  setTitle();
                  setBody();
                  setNewTemplateOpen(false);
                  setIsEditing(false);
                  setTemplates(
                    templates.map((t1) =>
                      t1.templateName === t.templateName ? t : t1
                    )
                  );
                });
              } else {
                createTemplate(t, () => {
                  setUserQuery();
                  setTitle();
                  setBody();
                  setNewTemplateOpen(false);
                  setIsEditing(false);
                  setTemplates([t, ...templates]);
                });
              }
            }}
          >
            Save
          </Button>
        </div>
      )}

      {!selectedTemplate && (
        <>
          <h1>Templates</h1>
          <ul
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <div>
              {templates.map((template) => {
                return (
                  <li>
                    <Button onClick={() => setSelectedTemplate(template)}>
                      <div>
                        <h2>{template.templateName}</h2>
                        <small>
                          {template.userIdQuery ===
                          ALL_MOBILE_NOTIFICATION_USERS_QUERY
                            ? "All Users"
                            : template.userIdQuery}
                        </small>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <IosNotification
                            title={template.title}
                            body={template.body}
                          />
                        </div>
                      </div>
                    </Button>
                    <Button
                      onClick={() => {
                        setTitle(template.title);
                        setUserQuery(template.userIdQuery);
                        setBody(template.body);
                        setTemplateName(template.templateName);
                        setDataMap(JSON.stringify(template.dataMap));
                        setCategory(template.category);
                        setNewTemplateOpen(true);
                        setIsEditing(true);
                      }}
                    >
                      <Edit />
                    </Button>
                    <Button
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to delete this notification?"
                          )
                        ) {
                          deleteNotification(template.templateName, () => {
                            setSelectedTemplate();
                            setTemplates([
                              ...templates.filter(
                                (t) => t.templateName !== template.templateName
                              ),
                            ]);
                          });
                        }
                      }}
                    >
                      <Delete />
                    </Button>
                  </li>
                );
              })}
            </div>
          </ul>
        </>
      )}

      {selectedTemplate && (
        <>
          <div>
            <Button
              onClick={() => {
                setSelectedTemplate();
                setFilteredUsers();
              }}
            >
              🔙 Back
            </Button>
            <h2>{selectedTemplate.templateName}</h2>
            <small>{selectedTemplate.userIdQuery}</small>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <IosNotification
                title={selectedTemplate.title}
                body={selectedTemplate.body}
              />
            </div>
            <div>
              <Button
                onClick={() =>
                  sendImpromptuNotification(
                    {
                      ...selectedTemplate,
                      dryRun: true,
                    },
                    (data) => setFilteredUsers(data)
                  )
                }
              >
                Check how many users will be sent this notification
              </Button>
              <br></br>
              {filteredUsers && filteredUsers.length > 0 && (
                <small>
                  Up to {filteredUsers.length} users will get this notification.
                </small>
              )}
              <br></br>
              <Button
                disabled={!filteredUsers || filteredUsers.length < 1}
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you want to send this notification to ${filteredUsers.length} users?`
                    )
                  ) {
                    sendImpromptuNotification(
                      {
                        ...selectedTemplate,
                        dryRun: false,
                      },
                      (data) => setFilteredUsers(data)
                    );
                  }
                }}
              >
                Send Notification
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
