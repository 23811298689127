import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import WebFont from "webfontloader";

// import * as Values from "tenit-values";
import { useEffect, Suspense, lazy } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import * as Feature from "./api/Features";
import NotificationStation from "./pages/internal/NotificationStation";
import ImageSearch from "./pages/onboard-beta/ImageSearch";
// import Login from './pages/Login';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60,
    },
  },
});

function App() {
  useEffect(() => {
    Feature.init(1001);
    // Load Values
    //Values.init("I06aO3t7BquvtodbI1rHUzdfNUw2zNq2pUlpeiIiYfo=", "portal.earnz.com:qa", ["SHOW_OPERATING_HOURS_INPUT", "SHOW_OTHER_IMAGES_INPUT"]);
  }, []);

  // Load Fonts
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Manrope"],
      },
      // fonts: {
      //   families: ['Bozon']
      // }
    });
  }, []);

  const localStoragePersistor = createWebStoragePersistor({
    storage: window.localStorage,
  });

  persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
  });

  // Lazy Page imports for code-splitting reasons
  const Promotions = lazy(() => import("./pages/Promotions"));
  const BusinessSearch = lazy(() => import("./pages/internal/BusinessSearch"));
  const EditPromotions = lazy(() => import("./pages/EditPromotions"));
  const Overwatch = lazy(() => import("./pages/internal/Overwatch"));
  const EditBusiness = lazy(() => import("./pages/EditBusiness"));
  const Loggy = lazy(() => import("./pages/internal/Loggy"));
  const ManageDeployable = lazy(() =>
    import("./pages/internal/ManageDeployable")
  );
  const CustomerActivityFeed = lazy(() =>
    import("./pages/internal/CustomerActivityFeed")
  );
  const GlobalActivityFeed = lazy(() =>
    import("./pages/internal/GlobalActivityFeed")
  );
  const CustomerHeatmapPage = lazy(() =>
    import("./pages/internal/CustomerHeatmapPage")
  );
  const ManageUsers = lazy(() => import("./pages/internal/ManageUsers"));
  const ManageCustomers = lazy(() =>
    import("./pages/internal/ManageCustomers")
  );
  const ManageValues = lazy(() => import("./pages/internal/ManageValues"));
  const ManageFeatures = lazy(() => import("./pages/internal/ManageFeatures"));
  const MetricsExplorer = lazy(() =>
    import("./pages/internal/MetricsExplorer")
  );
  const UsageAnalytics = lazy(() => import("./pages/internal/UsageAnalytics"));
  const Home = lazy(() => import("../src/pages/Home"));
  const Signup = lazy(() => import("../src/pages/Signup"));
  const CreatePromotions = lazy(() => import("./pages/CreatePromotions"));
  const Login = lazy(() => import("./pages/Login"));
  const OnboardV2 = lazy(() => import("./pages/Onboardv2"));
  const OnboardingBeta = lazy(() =>
    import("./pages/onboard-beta/OnboardingBeta")
  );
  const EventProviderOnboard = lazy(() =>
    import("./pages/EventProviderOnboard")
  );
  const RecentRedemptions = lazy(() =>
    import("./pages/internal/RecentRedemptions")
  );
  const TriggerPasswordReset = lazy(() =>
    import("./pages/TriggerPasswordReset")
  );
  const ResetPassword = lazy(() => import("./pages/ResetPassword"));

  const BillingAccounts = lazy(() =>
    import("./pages/internal/BillingAccounts")
  );
  const ManageBillingAccount = lazy(() =>
    import("./pages/internal/ManageBillingAccount")
  );
  const Dataviewer = lazy(() => import("./pages/internal/Dataviewer"));
  const SessionReplayPage = lazy(() =>
    import("./pages/internal/SessionReplayPage")
  );
  const SessionReplaysListingPage = lazy(() =>
    import("./pages/internal/SessionReplaysListingPage")
  );

  const StatusPage = lazy(() => import("./pages/internal/StatusPage"));

  const ApiCallerPage = lazy(() => import("./pages/internal/ApiCallerPage"));

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="App">
          <Suspense fallback={<div>Loading...</div>}>
            {/* <Navbar /> */}
            <Switch>
              {" "}
              <Route path="/internal/business">
                <BusinessSearch />
              </Route>
              <Route path="/internal/users">
                <ManageUsers />
              </Route>
              <Route path="/internal/status-page">
                <StatusPage />
              </Route>
              <Route path="/internal/api-caller">
                <ApiCallerPage />
              </Route>
              <Route exact path="/internal/billing-accounts">
                <BillingAccounts />
              </Route>
              <Route path="/internal/billing-accounts/:id">
                <ManageBillingAccount />
              </Route>
              <Route exact path="/internal/customers/heatmap">
                <CustomerHeatmapPage />
              </Route>
              <Route exact path="/internal/customers">
                <ManageCustomers />
              </Route>
              <Route path="/internal/recent-redemptions">
                <RecentRedemptions />
              </Route>
              <Route path="/internal/logs">
                <Loggy />
              </Route>
              <Route path="/internal/data">
                <Dataviewer />
              </Route>
              <Route path="/internal/session-replays/:sessionId">
                <SessionReplayPage />
              </Route>
              <Route path="/internal/session-replays">
                <SessionReplaysListingPage />
              </Route>
              <Route path="/internal/values">
                <ManageValues />
              </Route>
              <Route path="/internal/features">
                <ManageFeatures />
              </Route>
              <Route path="/internal/metrics-explorer">
                <MetricsExplorer />
              </Route>
              <Route path="/internal/usage-analytics">
                <UsageAnalytics />
              </Route>
              <Route path="/internal/notification-station">
                <NotificationStation />
              </Route>
              <Route path="/internal/overwatch/:deployableId">
                <ManageDeployable />
              </Route>
              <Route path="/internal/overwatch">
                <Overwatch />
              </Route>
              <Route path="/internal/customers/global/feed">
                <GlobalActivityFeed />
              </Route>
              <Route path="/internal/customers/:customerId/feed">
                <CustomerActivityFeed />
              </Route>
              <Route exact path="/" component={Home}></Route>
              <Route exact path="/home" component={Home}></Route>
              <Route exact path="/register" component={Signup}></Route>
              <Route exact path="/onboard-classic">
                <OnboardV2 />
              </Route>
              <Route exact path="/onboard">
                <OnboardingBeta />
              </Route>
              <Route exact path="/image-search-beta">
                <ImageSearch />
              </Route>
              <Route exact path="/event-provider/onboard">
                <EventProviderOnboard />
              </Route>
              <Route exact path="/:businessId/edit">
                <EditBusiness />
              </Route>
              <Route exact path="/:businessId/create-promotion">
                <CreatePromotions />
              </Route>
              <Route
                exact
                path="/:businessId/edit-promotion/:promoType/:promoId"
              >
                <EditPromotions />
              </Route>
              <Route
                exact
                path="/:businessId/promotions/:promoType/:promoId/view"
              >
                <EditPromotions readOnly={true} />
              </Route>
              <Route exact path="/:businessId/promotions">
                <Promotions />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/reset">
                <TriggerPasswordReset />
              </Route>
              <Route exact path="/reset-password">
                <ResetPassword />
              </Route>
              {/** Try something like like earnz.app/:businessId/dashboard and pull the businessId from that */}
            </Switch>
          </Suspense>
        </div>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
